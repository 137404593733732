import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import g from './globals'

gsap.registerPlugin(ScrollTrigger)

if (g.elementInDom('.banner')) {
    const banners = document.querySelectorAll('.banner')

    banners.forEach((obj, index) => {
        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=150px top',
                end:  '+=150%',
                scrub: true,
            }
        
        })
        
        tl.fromTo(obj.querySelector('img'),
            {y: 0,},
            {y: -100, duration: 500}
        )
    })
}