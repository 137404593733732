import g from './globals'


if (g.elementInDom('.sustainability')) {
    var descriptions = document.querySelectorAll('.description-item'),
        bottle = document.querySelector('.bottle-wrapper'),
        targets = document.querySelectorAll('svg g'),
        firstTarget =  document.querySelector('#target-1')
        
    firstTarget.classList.add('target-active')

    descriptions.forEach((obj, index)=> {
        var targetIndex = obj.dataset.index

        document.querySelector('#target-' + targetIndex).addEventListener('click', (e) => {
            e.preventDefault();

            new Promise((resolve, reject) => {
                hideAllDescriptions(resolve)
            })
            .then(() => {
                obj.classList.add('description-active')
                document.querySelector('#target-' + targetIndex).classList.add('target-active')
            })
            .catch((error) => {
                console.log(error)
            })
        })
    })

    function hideAllDescriptions(resolve) {
        targets.forEach((obj, index) => {
            obj.classList.remove('target-active')
        })
        descriptions.forEach((obj, index) => {
            obj.classList.remove('description-active')
        })
        resolve()
    }
}